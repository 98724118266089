/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import { useForm } from 'react-hook-form';
import Img from 'gatsby-image';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Hero from '../components/hero';
import FAQAccordion from '../components/faqaccordion';
import CalendlyThirty from '../components/calendly-30';

const IndexPage = () => {
  const HOW_TO_REPORT_SUBMITTED = '#how-to-report-submitted';
  const data = useStaticQuery(graphql`
    {
      onlineMarketingPhoto: file(
        relativePath: { eq: "man-on-phone-square.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      webDevelopmentPhoto: file(
        relativePath: { eq: "code-desktop-square.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      ebookCover: file(
        relativePath: {
          eq: "Double-Your-Website-Customers-in-the-Next-60-Days-cropped.png"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 850) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `);

  const [formError, setFormError] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const { register, handleSubmit, errors } = useForm();
  const onSubmit = async data => {
    // eslint-disable-next-line no-undef
    const response = await fetch('/api/how-to-report-form-submission', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      redirect: 'follow',
      body: JSON.stringify(data)
    });

    const json = await response.json();

    if (json.statusCode == 200) {
      setFormSubmitted(true);
    } else {
      setFormError(true);
    }
    return json;
  };

  return (
    <Layout>
      <SEO
        title="Home | Salt Lake City, Utah Web Design Expert"
        tabTitleOverride="Pega Digital | Home | Salt Lake City, UT Web Design Experts"
        description="The Web Design and Development experts in Salt Lake City, Utah. We work 1:1 on website & Google strategies to make you $. Click for a FREE strategy call!"
      />
      <Hero theme={`dark`} />
      <section className="section-secondary" style={{ minHeight: '400px' }}>
        <div className="container">
          <div
            className="paper paper-shadow"
            style={{
              position: 'relative',
              top: '-10rem',
              backgroundColor: '#fff'
            }}
          >
            <div className="paper--left">
              <Img
                fluid={data.ebookCover.childImageSharp.fluid}
                className="ebook"
              />
            </div>
            <div className="paper--right">
              {!formSubmitted && (
                <>
                  <h2 className="paper--left--header">
                    Double your web customers in the next 60 days
                  </h2>
                  <p>
                    This free, 5-minute-read report will provide you with actual
                    strategies I have used to double my clients&apos; traffic in
                    60 days.
                  </p>
                  <form
                    name="how-to-report"
                    onSubmit={handleSubmit(onSubmit)}
                    style={{ marginTop: '2rem' }}
                  >
                    <input type="hidden" name="bleh" />
                    <input
                      type="hidden"
                      name="form-name"
                      value="how-to-report"
                    />
                    <div className="input-wrapper">
                      <input
                        name="name"
                        ref={register}
                        type="text"
                        placeholder="Name"
                        style={{ width: '100%' }}
                      />
                    </div>
                    <div className="input-wrapper">
                      <input
                        name="email"
                        ref={register({ required: true })}
                        type="email"
                        placeholder="Email"
                        style={{ width: '100%' }}
                      />
                    </div>
                    <button
                      type="submit"
                      className="button button-primary"
                      style={{ width: '100%', maxWidth: 'none' }}
                    >
                      Email me the free report
                    </button>
                  </form>
                </>
              )}
              {formSubmitted && (
                <>
                  <h2>Thank you!</h2>
                  <p>Check your inbox for the report.</p>
                </>
              )}
              {formError && (
                <>
                  <h2>Something went wrong</h2>
                  <p>
                    Please email zach@pegadigital.com to let me know about the
                    problem and so I can get you the free report.
                  </p>
                </>
              )}
            </div>
          </div>
          <div className="back-n-forth">
            <article className="back-n-forth--item">
              <div className="back-n-forth--item--content">
                <h2 className="back-n-forth--item--content--header">
                  Online Marketing
                </h2>
                <div className="back-n-forth--item--content--details">
                  <p>
                    Every business needs to appear where their customers are
                    searching. These searches are more and more often on the
                    internet. In fact, over 90% of your customers are searching
                    for services like yours, online.
                  </p>
                  <p>
                    You&apos;re the expert on your business. You don&apos;t have
                    time to invest your precious time into web marketing. Leave
                    it to the experts! I&apos;ve been doing online marketing for
                    over 5 years with great results for my clients. My services
                    will help your business to get more customers, more positive
                    reviews, and more revenue. In fact,{' '}
                    <strong>I guarantee it</strong>.
                  </p>
                  <p>
                    If you&apos;re looking for any of the following then
                    you&apos;re in the right place:
                  </p>
                  <ul>
                    <li>
                      Appear before your competitors in Google search results
                      (SEO)
                    </li>
                    <li>A dramatic increase in positive Google reviews</li>
                    <li>More leads and customers</li>
                    <li>
                      More time to spend on your business &amp; the things that
                      matter most
                    </li>
                    <li>
                      <span className="color-primary">+ more!</span>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="back-n-forth--item--img">
                <Img fluid={data.onlineMarketingPhoto.childImageSharp.fluid} />
              </div>
            </article>
            <article className="back-n-forth--item">
              <div className="back-n-forth--item--content">
                <h2 className="back-n-forth--item--content--header">
                  Web Design &amp; Development
                </h2>
                <div className="back-n-forth--item--content--details">
                  <p>
                    I have worked with local companies, small tech startups,
                    billion dollar unicorn tech companies, and e-commerce
                    companies. I know how to build a site that will fit your
                    needs now and that will scale to fit your future needs.
                  </p>
                  <p>
                    If you&apos;re looking for any of the following technical
                    services then you&apos;re in the right place:
                  </p>
                  <ul>
                    <li>First website</li>
                    <li>Website migration</li>
                    <li>Internationalization (i18n)</li>
                    <li>Web design</li>
                    <li>Gatsby.js development</li>
                    <li>Shopify development</li>
                    <li>Webflow/WordPress/Drupal development</li>
                    <li>
                      Conversion rate optimization (CRO) / Multivariate (A/B)
                      testing
                    </li>
                    <li>
                      <span className="color-primary">+ more!</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="back-n-forth--item--img">
                <Img fluid={data.webDevelopmentPhoto.childImageSharp.fluid} />
              </div>
            </article>
          </div>
        </div>
      </section>
      <section className="faq">
        <div className="container">
          <h2 style={{ paddingTop: '4rem' }}>Frequently Asked Questions</h2>
          <FAQAccordion />
        </div>
      </section>
      <section className="prefooter-cta section-primary">
        <CalendlyThirty />
      </section>
    </Layout>
  );
};

export default IndexPage;
