/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';

const CalendlyThirty = () => {
  useEffect(() => {
    // eslint-disable-next-line no-undef
    const head = document.querySelector('head');
    // eslint-disable-next-line no-undef
    const script = document.createElement('script');
    script.setAttribute(
      'src',
      'https://assets.calendly.com/assets/external/widget.js'
    );
    script.setAttribute('defer', '');
    head.appendChild(script);

    return () => head.removeChild(script);
  }, []);
  return (
    <div
      className="container"
      id="schedule-a-call"
      style={{ paddingTop: '4rem', paddingBottom: '4rem' }}
    >
      <h2 className="prefooter-cta--header">Ready to get started?</h2>
      <h5 style={{ display: 'block' }}>Let&apos;s talk!</h5>
      <div
        className="calendly-inline-widget"
        data-url="https://calendly.com/pegadigital-zach/30min-web-consultation?text_color=000000&primary_color=8219ff"
        style={{ minWidth: '300px', height: '700px', overflowY: 'hidden' }}
      ></div>
    </div>
  );
};

export default CalendlyThirty;
